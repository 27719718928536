import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { useStudentName } from 'store/UserContext';
import { useHistory } from 'react-router-dom';
import getInitialsFromName from 'utils/getInitialsFromName';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

// MUI IMPORTS
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Drawer,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import { Settings, Logout } from '@mui/icons-material';
import {
  getUserPreferences,
  updateUserPreferenceById,
  createUserPreference,
} from 'components/counselor/functions';
import SettingsIcon from '@mui/icons-material/Settings';

// id is user_preference_id so that the patch request can be used
const DEFAULT_STATE = {
  notification: { id: null, enabled: false },
  task: { id: null, enabled: false },
  test_report: { id: null, enabled: false },
  message: { id: null, enabled: false },
};

export const PushNotificationPreferences = ({ userId }) => {
  const [smsSettings, setSmsSettings] = useState(DEFAULT_STATE);
  const [emailSettings, setEmailSettings] = useState(DEFAULT_STATE);
  const [cookie] = useCookies(['tokens']);
  const { enqueueSnackbar } = useSnackbar();

  const updatePreferenceByType = (type, feature, enabled, id = null) => {
    if (type === 'email') {
      setEmailSettings((prevState) => {
        const newState = { ...prevState };
        newState[feature] = { id, enabled };
        return newState;
      });
    } else if (type === 'sms') {
      setSmsSettings((prevState) => {
        const newState = { ...prevState };
        newState[feature] = { id, enabled };
        return newState;
      });
    }
  };

  const handleAPIChange = async (
    type,
    feature,
    enabled,
    userPreferenceId = null,
  ) => {
    try {
      if (userPreferenceId !== null) {
        const response = await updateUserPreferenceById(
          cookie,
          userPreferenceId,
          type,
          feature,
          enabled,
        );
        if (response) {
          updatePreferenceByType(type, feature, enabled, userPreferenceId);
        } else {
          updatePreferenceByType(type, feature, !!enabled, userPreferenceId);
        }
      } else {
        const response = await createUserPreference(
          cookie,
          type,
          feature,
          enabled,
        );
        if (response?.data?.id) {
          updatePreferenceByType(type, feature, enabled, response.data.id);
        } else {
          updatePreferenceByType(type, feature, !!enabled);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePreferenceChange = async (id, event) => {
    const { name, checked } = event.target;
    const [type, feature] = name.split('-');
    handleAPIChange(type, feature, checked, id);
    updatePreferenceByType(type, feature, checked, id);
  };

  useEffect(() => {
    const getPreferencesByUserId = async () => {
      const response = await getUserPreferences(cookie);

      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        setSmsSettings((prevState) => {
          const newState = response.data.reduce((acc, curr) => {
            const {
              attributes: { feature, enabled, type },
              id,
            } = curr;
            if (type === 'sms') {
              acc[feature] = { id, enabled };
            }
            return acc;
          }, {});
          return { ...prevState, ...newState };
        });

        setEmailSettings((prevState) => {
          const newState = response.data.reduce((acc, curr) => {
            const {
              attributes: { feature, enabled, type },
              id,
            } = curr;
            if (type === 'email') {
              acc[feature] = { id, enabled };
            }
            return acc;
          }, {});
          return { ...prevState, ...newState };
        });
      }
    };
    getPreferencesByUserId();
  }, [cookie, enqueueSnackbar]);

  return (
    <FormGroup>
      <Grid>
        <Typography sx={{ m: 2, textAlign: 'center' }}>
          <strong>Text Notifications</strong>
        </Typography>
        <Stack sx={{ m: 2 }}>
          {[...Object.keys(smsSettings)].map((key) => {
            const title = key.replace('_', ' ');
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={smsSettings[key].enabled}
                    onChange={(event) =>
                      handlePreferenceChange(smsSettings[key].id, event)
                    }
                    name={`sms-${key}`}
                  />
                }
                label={`New ${title}`}
              />
            );
          })}
        </Stack>
        <Typography sx={{ m: 2, textAlign: 'center' }}>
          <strong>Email Notifications</strong>
        </Typography>
        <Stack sx={{ m: 2 }}>
          {[...Object.keys(emailSettings)].map((key) => {
            const title = key.replace('_', ' ');
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={emailSettings[key].enabled}
                    onChange={(event) =>
                      handlePreferenceChange(emailSettings[key].id, event)
                    }
                    name={`email-${key}`}
                  />
                }
                label={`New ${title}`}
              />
            );
          })}
        </Stack>
      </Grid>
    </FormGroup>
  );
};

export const SettingsAccountMenu = () => {
  const [, , removeCookie] = useCookies(['tokens']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const studentName = useStudentName();
  const initials = getInitialsFromName(studentName);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogOut = () => {
    removeCookie('tokens');
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <SettingsIcon
              sx={{ backgroundColor: 'transparent', color: '#425ce8' }}
              style={{ cursor: 'pointer' }}
            >
              {initials}
            </SettingsIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem onClick={() => history.push(`/profile`)}>
          <Avatar sx={{ backgroundColor: '#425ce8' }}>{initials}</Avatar>{' '}
          Profile
        </MenuItem>
        <Divider /> */}
        <MenuItem onClick={handleDrawerOpen}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Preferences
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 350 }}>
          <PushNotificationPreferences />
        </Box>
      </Drawer>
    </>
  );
};
