const register = async (data, cookie) => {
  let url = `/auth/register`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  delete data.rePassword;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  if (cookie) {
    myHeaders.append('token', cookie?.tokens?.token);
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow',
  };

  const response = await fetch(url, requestOptions);

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 401) {
    return { error: 'User already exists.' };
  }

  return { error: 'server error.' };
};

export default register;

export const registerLink = async (data) => {
  let url = `/auth/student/register`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  delete data.rePassword;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, requestOptions);

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 401) {
    return { error: 'User already exists.' };
  }

  return { error: 'server error.' };
};

export const registerStudentCsv = async (csvData) => {
  let url = `/auth/student/register/csv`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'multipart/form-data');

  console.log(csvData, 86);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: csvData,
  };

  const response = await fetch(url, requestOptions);

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 400) {
    return { error: 'Validation Error' };
  }

  if (response.status === 401) {
    return { error: 'User already exists.' };
  }

  if (response.status === 500) {
    return {
      error: 'An error occurred while inserting data into the database.',
    };
  }

  return { error: 'server error.' };
};
