import React from 'react';

//MUI IMPORTS
import {
  Box,
  Typography,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  Divider,
  Grid,
  Checkbox,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDialog = ({
  isOpen,
  handleClose,
  content,
  completed,
  dueDate,
  handleComplete,
  id,
}) => {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="college-task-description"
      PaperProps={{
        sx: {
          minWidth: '35rem',
          maxHeight: '100vh',
          borderRadius: '15px',
          overflow: 'hidden',
          py: 0,
        },
      }}
    >
      <Box
        sx={{
          zIndex: '10',
          borderRadius: '15px 15px 0 0',
          backgroundColor: '#455FE8',
          position: 'relative',
        }}
      >
        <Stack
          sx={{ direction: 'row', justify: 'space-between', align: 'center' }}
        >
          <Avatar
            sx={{
              ml: 65,
              width: '3rem',
              height: '3rem',
              borderRadius: '50%',
              backgroundColor: '#455FE8',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <Typography variant="h4" style={{ cursor: 'pointer' }}>
              X
            </Typography>
          </Avatar>
        </Stack>
      </Box>
      <DialogContent>{content}</DialogContent>
      <Divider
        variant="middle"
        sx={{ height: '3px', backgroundColor: '#455FE8' }}
      />
      <DialogActions
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mx: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              {completed ? (
                <Typography sx={{ backgroundColor: '#C1C9F9' }}>
                  Completed{' '}
                  {dueDate.toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </Typography>
              ) : (
                <Typography sx={{ backgroundColor: '#E8E8E8' }}>
                  Due{' '}
                  {dueDate
                    ? dueDate.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : ''}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Checkbox
          sx={{ transform: 'scale(2)', m: 1 }}
          value={id}
          checked={completed}
          onChange={(e) => handleComplete(e)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
