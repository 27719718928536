import React from 'react';
import TaskCard from './TaskCard';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { updateComplete } from './functions/requests';
import { ResourceHOC } from './ResourceHOC';
import { isEmpty } from 'lodash';
// MUI IMPORTS

import { Grid, Divider } from '@mui/material';

export const AllTasks = ({ tasks, taskError, taskLoading, setTasks }) => {
  const [cookies] = useCookies(['tokens']);
  const { enqueueSnackbar } = useSnackbar();

  const handleComplete = async (e) => {
    const taskId = e.target.value;
    let task = tasks.find((task) => task.id === Number(taskId));

    const response = await updateComplete(cookies, task);

    if (response) {
      const status = task.completed ? 'upcoming' : 'completed';
      enqueueSnackbar(`Task set to ${status}`, {
        variant: 'success',
      });
      setTasks((prevTasks) => {
        const newTasks = prevTasks.map((task) => {
          if (task.id === Number(taskId)) {
            return {
              ...task,
              completed: !task.completed,
              completedDate: !task.completed ? new Date() : null,
            };
          }
          return task;
        });
        return newTasks;
      });
    } else {
      enqueueSnackbar('Error completing task', {
        variant: 'error',
      });
    }
  };

  return (
    <ResourceHOC
      isLoading={taskLoading}
      serverError={taskError}
      isEmpty={isEmpty(tasks)}
    >
      {tasks &&
        tasks.map((task) => {
          return (
            <>
              <Grid item key={task.id} xs={12}>
                <TaskCard handleComplete={handleComplete} {...task} />
              </Grid>
              <Divider
                variant="middle"
                sx={{ height: '3px', backgroundColor: '#D9D9D9' }}
              />
            </>
          );
        })}
    </ResourceHOC>
  );
};

export default AllTasks;
