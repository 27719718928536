import React, { useState } from 'react';
import ModalDialog from '../ModalDialog';
import CollegeContent from './CollegeContent';
import PropTypes from 'prop-types';

//MUI IMPORTS
import {
  Box,
  Grid,
  Divider,
  Typography,
  IconButton,
  Checkbox,
  Chip,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const tagColors = {
  Profile: '#C1C9F9',
  Essays: '#62B4FF',
  Recommendations: '#C085C6',
  'College List': '#F99746',
  Finances: '#02EEEE',
  SAT: '#72BB8B',
  ACT: '#EB437F',
  Other: '#B3D949',
};

function truncateString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength) + '...';
  }
}

const Item = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
};

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export const TaskCard = ({
  id,
  college,
  name,
  description,
  dueDate,
  completed,
  completedDate,
  handleComplete,
  importance,
  notes,
  tags,
}) => {
  const [open, setOpen] = useState(false);
  const truncatedDescription = truncateString(description, 125);
  const currentDate = new Date();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            m: 1,
            mb: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.875rem',
            fontWeight: '700',
            marginLeft: 'auto',
          }}
        >
          {completed ? (
            <CheckBoxIcon
              sx={{
                mt: '1rem',
                transform: 'scale(2)',
                color: '#C1C9F9',
              }}
            />
          ) : dueDate > currentDate ? (
            <AccessTimeIcon
              sx={{
                mt: '1rem',
                transform: 'scale(2)',
                color: '#43A047',
              }}
            />
          ) : (
            <ErrorIcon
              sx={{
                mt: '1rem',
                transform: 'scale(2)',
                color: '#D32F2F',
              }}
            />
          )}

          <Checkbox
            sx={{ mt: '1rem', transform: 'scale(2)' }}
            value={id}
            checked={completed}
            onChange={(e) => handleComplete(e)}
          />
        </Box>
        <Box
          sx={{
            m: 1,
            mb: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.875rem',
            fontWeight: '700',
            marginLeft: 'auto',
          }}
        >
          <Item sx={{ flexGrow: 0, height: 100 }}>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                display: { xs: 'none', md: 'block' },
                borderColor: '#000000',
                borderLeftWidth: '3px',
                borderRightWidth: '0',
                margin: 'auto',
              }}
            />
          </Item>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 1,
            m: 1,
            maxWidth: 'calc(100% - 200px)',
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Item sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                {completed ? (
                  <Typography sx={{ backgroundColor: '#C1C9F9' }}>
                    Completed{' '}
                    {dueDate.toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </Typography>
                ) : (
                  <Typography sx={{ backgroundColor: '#E8E8E8' }}>
                    Due{' '}
                    {dueDate.toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </Typography>
                )}
              </Grid>
            </Item>
            <Item sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="h2">
                {name}
              </Typography>
            </Item>
            <Item sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="h2">
                {tags && tags.length > 0 ? (
                  tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.name}
                      style={{ backgroundColor: tagColors[tag.name] }}
                    />
                  ))
                ) : (
                  <span>No tags</span>
                )}
              </Typography>
            </Item>
            {college && college.data?.attributes?.name ? (
              <Item sx={{ flexGrow: 1 }}>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ alignSelf: 'flex-end' }}
                >
                  {college.data.attributes.name}
                </Typography>
              </Item>
            ) : (
              <Item sx={{ flexGrow: 1 }}>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ alignSelf: 'flex-end' }}
                >
                  General
                </Typography>
              </Item>
            )}
          </Box>
          <Item>
            <Typography variant="body1" component="p">
              {truncatedDescription}
            </Typography>
          </Item>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 1,
            m: 1,
            borderRadius: 1,
          }}
        >
          <Item>
            <ModalDialog
              isOpen={open}
              handleClose={() => setOpen(false)}
              completed={completed}
              dueDate={dueDate}
              handleComplete={handleComplete}
              id={id}
              content={
                <CollegeContent
                  why={importance}
                  how={notes}
                  description={description}
                  title={name}
                />
              }
            />
            <IconButton
              onClick={() => setOpen(true)}
              aria-label="expand more options"
              sx={{ color: completed ? 'white' : '#1A3AD5' }}
            >
              <ArrowForwardIosIcon
                sx={{ transform: 'scale(2)', color: '#455FE8' }}
              />
            </IconButton>
          </Item>
        </Box>
      </Box>

      <Box>
        {completed ? (
          <Typography
            sx={{ flexGrow: 1, fontSize: '1rem' }}
            gutterBottom
            variant="h6"
            component="div"
            color="white"
          >
            Completed on {completedDate.toLocaleDateString('en-US')}
          </Typography>
        ) : (
          <Box sx={{ flexGrow: 1 }}></Box>
        )}
      </Box>
    </>
  );
};

export default TaskCard;
