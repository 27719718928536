import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

const setHeaders = (cookie) => {
  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};

const setUrl = (url) => {
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  return url;
};

export const useFetchTasks = (url) => {
  const [cookie] = useCookies(['token']);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    const uri = setUrl(url);
    const myHeaders = setHeaders(cookie);
    const configObj = {
      method: 'GET',
      headers: myHeaders,
    };

    setIsLoading(true);
    const getData = async () => {
      try {
        const res = await fetch(uri, configObj);
        const { data } = await res.json();
        if (data) {
          const newData = data.map((d) => {
            const { dueDate, completedDate } = d.attributes;
            const { college } = d.relationships;
            const { data } = d.relationships.task;
            const {
              attributes: { description, name, type, notes, importance, tags },
            } = data;
            return {
              id: d.id,
              college: college,
              dueDate: new Date(dueDate),
              completedDate: completedDate ? new Date(completedDate) : null,
              completed: !!completedDate,
              description: description,
              name: name,
              type: type,
              notes: notes,
              importance: importance,
              tags: tags,
            };
          });
          setResponse(newData);
        }

        setIsLoading(false);
      } catch (e) {
        setServerError(
          `Unable to process API request to: ${url} REASON: ${e.message}`,
        );
      }
    };
    getData();
  }, [url, cookie]);

  return [response, setResponse, isLoading, serverError];
};

export const useFetchColleges = (url) => {
  const [cookie] = useCookies(['token']);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    const uri = setUrl(url);
    const myHeaders = setHeaders(cookie);
    const configObj = {
      method: 'GET',
      headers: myHeaders,
    };

    setIsLoading(true);
    const getData = async () => {
      try {
        const res = await fetch(uri, configObj);
        const json = await res.json();
        const {
          body: { data },
        } = json;

        setResponse(data);

        setIsLoading(false);
      } catch (e) {
        setServerError(
          `Unable to process API request to: ${url} REASON: ${e.message}`,
        );
      }
    };
    getData();
  }, [url, cookie]);

  return [response, isLoading, serverError];
};
