// OPEN
import Threads from 'components/Threads';
import LandingPage from 'navigation/LandingPage';
import DownloadApp from 'components/DownloadApp';
import PasswordResetForm from 'navigation/PasswordResetForm';
import PasswordResetRequest from 'navigation/PasswordResetRequest';
import RefreshSession from 'components/RefreshSession';
import { DrawerAndNavMessages } from 'components/messaging/MessagingLayout';
import SignUp from 'navigation/SignUp';

// STUDENT
import Guides from 'components/student/guides/Layout';
import Tasks from 'components/student/tasks-v2/Layout';
import Colleges from 'components/student/colleges/Layout';
import { Layout as ProfileDownload } from '../components/student/pdf/Layout';
import { Layout as StudentProfile } from '../components/student/profile/Layout';
import { EdVizeStudentDashboard } from 'components/student/dashboard/myDashboard';
import { Layout as EditPassword } from '../components/student/edit-password/Layout';
import CollegeSurvey from 'components/student/colleges/college-survey/Layout';
import ExamEntry from 'components/studentExamEntry/studentExamEntry';
import ExamReportsPage from 'components/student/ExamReportsPage';
import { DrawerAndNavNotifications } from 'components/student/notifications/NotificationLayout';

//COUNSELOR
import StudentsCounselor from 'components/counselor/studentsCounselor';
import TasksCounselor from 'components/counselor/task-counselor/tasksCounselor';
import CounselorExamEntry from 'components/counselor/counselorExamEntry';
import CounselorEditExamEntry from 'components/counselor/counselorEditExamEntry';
import CounselorGradedPracticeTest from 'components/counselor/gradedPracticeTests';
import { StudentExamReport } from 'components/counselor/studentExamReport';
import AdminSignUp from 'components/counselor/addAdminAccount';
import ProfilePage from 'components/counselor/profilePage';
// import { MyStudentsCounselor } from 'components/counselor/myStudentsCounselor';

// EXAM ADMIN
import ExamAdminExamEntry from 'components/exam-admin/examAdminExamEntry';

// ADMIN
import ShoppingCart from 'components/ShoppingCart';
import ScheduleHelp from 'components/ScheduleHelp';
import PrivacyPolicy from 'components/PrivacyPolicy';
import VideoChat from 'components/sessions/VideoChat';
import StudentSnapshot from 'components/StudentSnapshot';
import DeleteAccount from 'components/admin/DeleteAccount';
import CounselorSchedule from 'components/CounselorSchedule';
import MyStudentSessions from 'components/MyStudentSessions';
import AvailableSessions from 'components/AvailableSessions';
import MyCounselorSessions from 'components/MyCounselorSessions';
import AdminManageSessions from 'components/AdminManageSessions';
import AdminNewTask from 'components/admin/tasks/new-task/NewTaskFormLayout';
import AdminEditTask from 'components/admin/tasks/edit-task/EditTaskFormLayout';
import AdminColleges from 'components/admin/admin-colleges/Layout';
import AdminAllStudentsList from 'components/admin/AdminAllStudentsList';
import { Layout as AdminProfile } from 'components/admin/profile/Layout';
import { IndexLayout as AdminStudents } from '../components/admin/students/IndexLayout';
import { SnapshotLayout as Snapshot } from '../components/admin/students/snapshot/SnapshotLayout';
import AdminTasks from '../components/admin/tasks/task-details/IndexLayout';
import Entities from '../components/admin/Entities';

// Account Admin
import Account from '../components/accountAdmin';
import AssignedStudentList from 'components/accountAdmin/AssignedStudentList';

export const openRoutes = [
  {
    path: '/request-password-reset',
    component: PasswordResetRequest,
  },
  {
    path: '/reset-password',
    component: PasswordResetForm,
  },
  {
    path: '/register',
    component: SignUp,
  },
  {
    path: '/',
    component: LandingPage,
  },
];

export const studentRoutes = [
  {
    path: '/refresh-session',
    component: RefreshSession,
  },
  {
    path: '/dashboard',
    component: EdVizeStudentDashboard,
  },
  {
    path: '/tasks',
    component: Tasks,
  },
  {
    path: '/profile/download',
    component: ProfileDownload,
  },
  {
    path: '/profile',
    component: StudentProfile,
  },
  {
    path: '/edit-password',
    component: EditPassword,
  },
  {
    path: '/colleges',
    component: Colleges,
  },
  {
    path: '/college-match/Layout',
    component: CollegeSurvey,
  },
  { path: '/notifications', component: DrawerAndNavNotifications },
  {
    path: '/studentExamEntry',
    component: ExamEntry,
  },
  {
    path: '/guides',
    component: Guides,
  },
  { path: '/messages', component: DrawerAndNavMessages },
  {
    path: '/exam-reports',
    component: ExamReportsPage,
  },
];

export const counselorRoutes = [
  {
    path: '/counselor/profile',
    component: ProfilePage,
  },
  {
    path: '/edit-password',
    component: EditPassword,
  },
  {
    path: '/counselor/counselor-students',
    component: StudentsCounselor,
  },
  {
    path: '/counselor/counselor-tasks',
    component: TasksCounselor,
  },
  {
    path: '/counselor/student-exam-entry',
    component: CounselorExamEntry,
  },
  {
    path: '/counselor/graded-practice-tests',
    component: CounselorGradedPracticeTest,
  },
  {
    path: '/counselor/student-exam-report',
    component: StudentExamReport,
  },
  {
    path: '/counselor/students/:id/snapshot',
    component: Snapshot,
  },
  {
    path: '/counselor/students/:id/exam/:examId',
    component: CounselorEditExamEntry,
  },
  { path: '/counselor/messages', component: DrawerAndNavMessages },
];

export const accountAdminRoutes = [
  {
    path: '/account-admin/profile',
    component: ProfilePage,
  },
  {
    path: '/account-admin/edit-password',
    component: EditPassword,
  },
  {
    path: '/account-admin/account-admin-students',
    component: StudentsCounselor,
  },
  {
    path: '/account-admin/account/:id',
    component: AssignedStudentList,
  },
  {
    path: '/account-admin/account',
    component: Account,
  },
  {
    path: '/account-admin/account-admin-tasks',
    component: TasksCounselor,
  },
  {
    path: '/account-admin/student-exam-entry',
    component: CounselorExamEntry,
  },
  {
    path: '/account-admin/graded-practice-tests',
    component: CounselorGradedPracticeTest,
  },
  {
    path: '/account-admin/student-exam-report',
    component: StudentExamReport,
  },
  {
    path: '/account-admin/register-user',
    component: AdminSignUp,
  },
  {
    path: '/account-admin/students/:id/snapshot',
    component: Snapshot,
  },
  {
    path: '/account-admin/students/:id/exam/:examId',
    component: CounselorEditExamEntry,
  },
  { path: '/account-admin/messages', component: DrawerAndNavMessages },
];

export const adminRoutes = [
  {
    path: '/admin/entities',
    component: Entities,
  },
  {
    path: '/admin/students/:id/snapshot',
    component: Snapshot,
  },
  {
    path: '/admin/students',
    component: AdminStudents,
  },
  {
    path: '/admin/tasks',
    component: AdminTasks,
  },
  {
    path: '/admin-new-task',
    component: AdminNewTask,
  },
  {
    path: '/admin-edit-task',
    component: AdminEditTask,
  },
  {
    path: '/admin-colleges',
    component: AdminColleges,
  },
  {
    path: '/admin-all-students-list',
    component: AdminAllStudentsList,
  },
  {
    path: '/admin-session-requests',
    component: AdminManageSessions,
  },
  {
    path: '/admin-delete-accounts',
    component: DeleteAccount,
  },
  {
    path: '/admin-student-snapshot',
    component: StudentSnapshot,
  },
  {
    path: '/available-sessions',
    component: AvailableSessions,
  },
  {
    path: '/my-sessions-students',
    component: MyStudentSessions,
  },
  {
    path: '/my-sessions-counselors',
    component: MyCounselorSessions,
  },
  {
    path: '/threads',
    component: Threads,
  },
  {
    path: '/download',
    component: DownloadApp,
  },
  {
    path: '/schedule',
    component: CounselorSchedule,
  },
  {
    path: '/admin-profile',
    component: AdminProfile,
  },
  {
    path: '/schedule-help',
    component: ScheduleHelp,
  },
  {
    path: '/cart',
    component: ShoppingCart,
  },
  {
    path: '/lobby',
    component: VideoChat,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
];

export const examAdminRoutes = [
  {
    path: '/exam-admin/profile',
    component: ProfilePage,
  },
  {
    path: '/exam-admin/students',
    component: StudentsCounselor,
  },
  {
    path: '/exam-admin/exam-entry',
    component: ExamAdminExamEntry,
  },
  { path: '/exam-admin/messages', component: DrawerAndNavMessages },
];
