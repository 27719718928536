import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { registerStudentCsv } from 'components/userManagmentFunctions/register';
import { useSnackbar } from 'notistack';

// MUI IMPORTS
import { Box, Button, Typography, Stack, Grid } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UploadIcon from '@mui/icons-material/Upload';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const useStyles = makeStyles((theme) => ({
  browseText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fileInput: {
    display: 'none',
  },
}));

export const StudentCsvDropzone = () => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const fileSizeConverter = (bytes) => {
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return size.toFixed(2) + ' ' + units[unitIndex];
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      enqueueSnackbar('Please select files to upload.', { variant: 'error' });
      return;
    }

    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }

      const response = await registerStudentCsv(formData);
      console.log(formData, 55);

      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
        setUploadStatus('error');
      } else {
        enqueueSnackbar(response.message, { variant: 'success' });
        setUploadStatus('success');
      }
    } catch (error) {
      enqueueSnackbar('Error sending data', { variant: 'error' });
      setUploadStatus('error');
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileAdd = (event, droppedFiles) => {
    const addedFiles = droppedFiles || Array.from(event.target.files);

    const csvFiles = addedFiles.filter((file) => {
      const isCSV = file.type === 'text/csv' || file.name.endsWith('.csv');
      return isCSV;
    });

    if (csvFiles.length > 0) {
      setFiles([csvFiles[0]]);
    }
  };

  const handleFileRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#DCDCDC',
          borderRadius: '5px',
          m: 5,
          height: 150,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        onDragOver={(event) => event.preventDefault()}
        onDrop={(event) => {
          event.preventDefault();
          const droppedFiles = Array.from(event.dataTransfer.files);
          handleFileAdd(null, droppedFiles);
        }}
      >
        {files.length > 0 ? (
          <>
            {files.map((file, index) => (
              <Box key={file.id}>
                <Stack>
                  <Grid
                    container
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        backgroundColor:
                          uploadStatus === 'success'
                            ? '#3CB371' // Green for success
                            : uploadStatus === 'error'
                            ? '#CC252C' // Red for error
                            : '#808080', // Gray for default
                        borderRadius: '5px',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                      }}
                    >
                      <Stack>
                        <Typography sx={{ fontSize: '15px', color: 'white' }}>
                          {file.name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ fontSize: '10px', color: 'white' }}
                        >
                          {file.type}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ fontSize: '10px', color: 'white' }}
                        >
                          {fileSizeConverter(file.size)}
                        </Typography>
                      </Stack>

                      <HighlightOffIcon
                        sx={{ ml: 1, color: '#DCDCDC', fontSize: '38px' }}
                        onClick={() => handleFileRemove(file)}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            ))}
          </>
        ) : (
          <Typography>
            Drag & drop your files or{' '}
            <span className={classes.browseText} onClick={handleBrowseClick}>
              Browse
            </span>
            <input
              type="file"
              className={classes.fileInput}
              ref={fileInputRef}
              onChange={handleFileAdd}
              multiple
            />
          </Typography>
        )}
      </Box>
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={files.length === 0}
      >
        Upload
      </Button>
    </Box>
  );
};
