import React, { useState, useEffect } from 'react';
import Day from './Day';

import {
  format,
  startOfWeek,
  startOfMonth,
  isBefore,
  endOfMonth,
  sub,
  add,
} from 'date-fns';

// MUI IMPORTS
import DatePicker from '@mui/lab/DatePicker';
import { useTheme } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Box, Grid, Typography, Stack, IconButton } from '@mui/material';

const makeDateKey = (date) => {
  const dateKey = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;

  return { dateKey };
};

const Layout = ({ setTasks, tasks, taskLoading, taskError }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);

  useEffect(() => {
    setStartDay(startOfWeek(startOfMonth(startDate), { weekStartsOn: 0 }));
    setEndDay(endOfMonth(startDate));
  }, [tasks, startDate, setEndDay, setStartDay]);

  const [tasksMap, tasksMapSet] = useState({});

  useEffect(() => {
    const tempMap = tasks.reduce((acc, task) => {
      let date;
      task.completed
        ? (date = new Date(task.completedDate))
        : (date = new Date(task.dueDate));
      const { dateKey } = makeDateKey(date);
      !!acc[dateKey] ? acc[dateKey].push(task) : (acc[dateKey] = [task]);
      return acc;
    }, {});
    tasksMapSet(tempMap);
  }, [tasks, tasksMapSet]);

  const [calendar, calendarSet] = useState([]);

  useEffect(() => {
    const cal = Array.from({ length: 5 }, (_, weekIndex) =>
      Array.from({ length: 7 }, (_, dayIndex) => {
        let day = add(startDay, { days: weekIndex * 7 + dayIndex });
        const { dateKey } = makeDateKey(day);
        const config = {
          day,
          dateKey,
          tasks: tasksMap[dateKey] || [],
        };
        return config;
      }),
    );

    calendarSet(cal);
  }, [tasksMap, startDay]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <CalendarHeader startDate={startDate} setStartDate={setStartDate} />
        {calendar.map((week, i) => (
          <Grid
            container
            item
            xs={12}
            key={i}
            columns={7}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {week.map((day, j) => (
              <Day
                key={`week-${i}-day${j}`}
                day={day}
                startDate={startDate}
                endDay={endDay}
              />
            ))}
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default Layout;

const CalendarHeader = ({ startDate, setStartDate }) => {
  const weekStart = startOfWeek(startDate, { weekStartsOn: 0 });

  const weekdays = [...Array(7)].map((_, i) => {
    const day = add(weekStart, { days: i });
    return (
      <Grid
        item
        key={i}
        sx={{
          display: { xs: 'none', md: 'block' },
          textAlign: 'center',
        }}
      >
        <Typography
          variant="body2"
          component="p"
          sx={{
            fontWeight: 'bold',
            color: 'textPrimary',
            fontSize: { md: '.75rem', lg: '1.5rem' },
          }}
        ></Typography>
      </Grid>
    );
  });

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={2}></Grid>
        <Grid container item xs={8} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={1} justifyContent="center">
              <IconButton
                size="large"
                color="primary"
                aria-label="navigate to previous month"
                component="button"
                onClick={() => setStartDate((prev) => sub(prev, { months: 1 }))}
              >
                <ArrowCircleLeftOutlinedIcon />
              </IconButton>
              <Typography variant="h1" component="h6" gutterBottom>
                {format(startDate, 'MMMM')}
              </Typography>
              <IconButton
                size="large"
                color="primary"
                aria-label="navigate to next month"
                component="button"
                onClick={() => setStartDate((prev) => add(prev, { months: 1 }))}
              >
                <ArrowCircleRightOutlinedIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid container textAlign="center" columns={7}>
            {[
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ].map((day, i) => {
              return (
                <Grid
                  key={`${day}-${i}`}
                  item
                  sx={{ display: { xs: 'none', md: 'block' } }}
                  md={1}
                >
                  <Typography
                    key={i}
                    variant="body2"
                    component="p"
                    sx={{
                      fontWeight: 'bold',
                      color: 'textPrimary',
                      textAlign: 'center',
                      fontSize: { md: '.75rem', lg: '1.5rem' },
                    }}
                  >
                    {day}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Grid container textAlign="center" columns={7}>
        {weekdays}
      </Grid>
    </>
  );
};
