import React, { useState, useEffect } from 'react';
import { useStudentName } from 'store/UserContext';
import getInitialsFromName from 'utils/getInitialsFromName';
import { EditProfileForm } from 'components/student/profile/EditProfileForm';
import ModalDialog from 'components/student/ModalDialog';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { Layout as EditPassword } from 'components/student/edit-password/Layout';
import { useAuthUser } from 'store/UserContext';

// REACTLY - ICONS
import { Location, EditSquare as EditIcon } from 'react-iconly';

// MUI IMPORTS
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const Bold = ({ children }) => {
  return <Box sx={{ display: 'inline', fontWeight: 'bold' }}>{children}</Box>;
};

export default function ProfilePage() {
  const studentName = useStudentName();
  const initials = getInitialsFromName(studentName);
  const [open, setOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const user = useAuthUser();

  return (
    <Grid
      container
      sx={{
        spacing: 0,
        direction: 'column',
        alignItems: 'center',
        justify: 'center',
      }}
    >
      <Card sx={{ width: 500, height: 500 }} center>
        <CardHeader
          sx={{ backgroundColor: '#425ce8' }}
          avatar={
            <Avatar
              sx={{ bgcolor: '#42a5f5', border: '3px solid white' }}
              aria-label="user"
            >
              {initials}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <ModeEditOutlineIcon
                sx={{ color: '#ffffff' }}
                onClick={(e) => setOpen(true)}
              />
            </IconButton>
          }
          title={
            <Typography color="#ffffff">
              <Bold>{studentName}</Bold>
            </Typography>
          }
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            <Bold>First Name:</Bold> {user.first_name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Bold>Last Name:</Bold> {user.last_name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Bold>Email:</Bold> {user.email}
          </Typography>
          <ModalDialog
            isOpen={open}
            handleClose={() => setOpen(false)}
            content={
              <EditProfileForm
                profile={{
                  first_name: user.first_name,
                  last_name: user.last_name,
                  email: user.email,
                }}
                handleClose={() => setOpen(false)}
              />
            }
            title="Edit Profile"
          />
        </CardContent>
        <CardActions>
          <ModalDialog
            isOpen={passwordOpen}
            handleClose={() => setPasswordOpen(false)}
            content={
              <EditPassword handleClose={() => setPasswordOpen(false)} />
            }
            title="Edit Password"
          />
          <IconButton
            size="small"
            sx={{ height: '25px' }}
            onClick={(e) => setPasswordOpen(true)}
          >
            <EditIcon set="bold" style={{ color: '#455FE8', height: '25px' }} />
            Update Password
          </IconButton>{' '}
        </CardActions>
      </Card>
    </Grid>
  );
}
