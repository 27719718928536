import React, { useState, useEffect } from 'react';
import { getUserColleges } from '../colleges-user/functions/requests';
import { useCookies } from 'react-cookie';
import { getDateTakenResults } from 'components/counselor/functions';
import { useSnackbar } from 'notistack';
import { useAuthUser } from 'store/UserContext';
import { subtractDays, formatDateISO } from 'utils/dates';
import { useHistory } from 'react-router-dom';
import { useFetchTasks, useFetchColleges } from '../tasks-v2/functions/hooks';
import './dashboardStyles.scss';

// MUI IMPORTS
import {
  Box,
  Typography,
  Grid,
  Stack,
  Divider,
  Switch,
  IconButton,
} from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import PercentIcon from '@mui/icons-material/Percent';

//RECHART IMPORTS
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const Item = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
};

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const DashboardGraphs = ({ selectedTest, graphData }) => {
  const [graphWidth, setGraphWidth] = useState(600);
  const [graphHeight, setGraphHeight] = useState(350);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1535 || window.innerHeight <= 1238) {
        setGraphWidth(325);
        setGraphHeight(225);
      } else {
        setGraphWidth(600);
        setGraphHeight(350);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {selectedTest === 'sat' ? (
        <div className="graph-container">
          <BarChart width={graphWidth} height={graphHeight} data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis type="number" domain={[0, 1600]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="Score" barSize={50} fill="#445CEC" />
          </BarChart>
        </div>
      ) : (
        <div className="graph-container">
          <BarChart width={graphWidth} height={graphHeight} data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis type="number" domain={[0, 36]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="Score" barSize={50} fill="#445CEC" />
          </BarChart>
        </div>
      )}
    </>
  );
};

const ProgressBar = ({ value, max, color, hideValue }) => {
  const normalizedValue = Math.min(value, max);
  const barWidth = `${(normalizedValue / max) * 100}%`;

  const barStyles = {
    width: barWidth,
    height: '100%',
    backgroundColor: color || '#445CEC',
    borderRadius: '30px',
  };

  return (
    <div style={barStyles}>
      {hideValue && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '5px',
            transform: 'translateY(-50%)',
          }}
        >
          {`${value}`}
        </div>
      )}
    </div>
  );
};

const T_DEFAULT_DATA = [
  { label: 'Profile', value: 16, color: '#C1C9F9' },
  { label: 'Essays', value: 10, color: '#62B4FF' },
  { label: 'Recommendations', value: 5, color: '#C085C6' },
  { label: 'College List', value: 16, color: '#F99746' },
  { label: 'Finances', value: 5, color: '#02EEEE' },
  { label: 'SAT', value: 5, color: '#72BB8B' },
  { label: 'ACT', value: 10, color: '#EB437F' },
  { label: 'Other', value: 9, color: '#B3D949' },
];

const TagProgressBars = ({ tasks }) => {
  if (!tasks) {
    tasks = [];
  }

  const maxTasks = 20;
  const tagCounts = {};
  const tagOverdueCounts = {};

  tasks.forEach((task) => {
    const tags = task.tags || [];
    tags.forEach((tag) => {
      if (tag.name in tagCounts) {
        tagCounts[tag.name] += 1;
      } else {
        tagCounts[tag.name] = 1;
      }
      const dueDate = task.dueDate;
      if (dueDate && dueDate < new Date() && tag.name === 'specificTag') {
        if (tag.name in tagOverdueCounts) {
          tagOverdueCounts[tag.name] += 1;
        } else {
          tagOverdueCounts[tag.name] = 1;
        }
      }
    });
  });

  const updatedData = T_DEFAULT_DATA.map((item) => ({
    ...item,
    value: tagCounts[item.label] || 0,
    overdue: tagOverdueCounts[item.label] || 0,
  }));

  return (
    <div>
      {updatedData.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            borderRadius: 1,
          }}
        >
          <Item
            sx={{
              flexBasis: '15%',
              flexGrow: 0,
              marginRight: '4px',
              fontSize: '14px',
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                minWidth: 100,
                mr: 4,
                textAlign: 'left',
                fontSize: '19px',
              }}
            >
              <strong>{item.label}</strong>
            </Typography>
          </Item>

          <Item
            sx={{
              flexBasis: '50%',
              flexGrow: 1,
              marginRight: '4px',
            }}
          >
            <ProgressBar value={item.value} max={maxTasks} color={item.color} />
          </Item>
          <Item
            sx={{
              flexBasis: '20%',
              flexGrow: 0,
            }}
          >
            <Typography variant="body2" color="text.secondary">
              <strong>{`${item.value} Tasks`}</strong>
            </Typography>
            {item.value > 15 && (
              <>
                <ErrorIcon sx={{ fontSize: 16, color: '#D32F2F' }} />
                <Typography variant="body2" color="error">
                  Overdue: {item.overdue}
                </Typography>
              </>
            )}
          </Item>
        </Box>
      ))}
    </div>
  );
};

const CollegeProgressBars = ({ tasks }) => {
  const maxTasks = 20;

  const collegeCounts = {};
  const collegeOverdueCounts = {};

  tasks.forEach((task) => {
    const collegeName = task.college?.data?.attributes?.name || 'General';

    if (collegeName in collegeCounts) {
      collegeCounts[collegeName] += 1;
    } else {
      collegeCounts[collegeName] = 1;
    }

    const dueDate = task.dueDate;
    if (dueDate && dueDate < new Date()) {
      if (collegeName in collegeOverdueCounts) {
        collegeOverdueCounts[collegeName] += 1;
      } else {
        collegeOverdueCounts[collegeName] = 1;
      }
    } else {
      if (!(collegeName in collegeOverdueCounts)) {
        collegeOverdueCounts[collegeName] = 0;
      }
    }
  });

  const updatedData = Object.keys(collegeCounts).map((collegeName) => ({
    label: collegeName,
    value: collegeCounts[collegeName],
    overdue: collegeOverdueCounts[collegeName],
    color: '#455FE8',
  }));

  console.log(tasks);

  return (
    <div>
      {updatedData.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 1,
            borderRadius: 1,
          }}
        >
          <Item
            sx={{
              flexBasis: '15%',
              flexGrow: 0,
              marginRight: '4px',
              fontSize: '14px',
            }}
          >
            <MuiTooltip title={item.label}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  minWidth: 100,
                  mr: 4,
                  textAlign: 'left',
                  fontSize: '15px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <strong>{item.label}</strong>
              </Typography>
            </MuiTooltip>
          </Item>

          <Item
            sx={{
              flexBasis: '50%',
              flexGrow: 1,
              marginRight: '4px',
            }}
          >
            <ProgressBar value={item.value} max={maxTasks} color={item.color} />
          </Item>

          <Item
            sx={{
              flexBasis: '20%',
              flexGrow: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                m: 1,
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mr: 1, fontSize: '12px' }}
              >
                <strong>{`${item.value} Tasks`}</strong>
              </Typography>
              {item.label === 'General' ? (
                <>
                  <ErrorIcon sx={{ fontSize: 12, color: '#D32F2F' }} />
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ fontSize: '12px' }}
                  >
                    Overdue: {item.overdue}
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: '12px' }}
                >
                  Overdue: {collegeOverdueCounts[item.label] || 0}
                </Typography>
              )}
            </Box>
          </Item>
        </Box>
      ))}
    </div>
  );
};

const DashboardTasks = ({ tasks }) => {
  const [viewMode, setViewMode] = useState('tag');
  const [overdueTasksCount, setOverdueTasksCount] = useState(0);
  const [upcomingTasksCount, setUpcomingTasksCount] = useState(0);
  const completedTasksCount = tasks
    ? tasks.reduce((count, task) => {
        if (task.completedDate !== null && task.completedDate !== undefined) {
          return count + 1;
        }
        return count;
      }, 0)
    : 0;

  useEffect(() => {
    if (tasks) {
      const currentDate = new Date();
      const count = tasks.reduce((total, task) => {
        if (task.dueDate && new Date(task.dueDate) < currentDate) {
          return total + 1;
        }
        return total;
      }, 0);
      setOverdueTasksCount(count);
    }
  }, [tasks]);

  useEffect(() => {
    if (tasks) {
      const currentDate = new Date();
      const count = tasks.reduce((total, task) => {
        if (
          (!task.completedDate ||
            task.completedDate === null ||
            task.completedDate === undefined) &&
          !task.completed &&
          new Date(task.dueDate) >= currentDate
        ) {
          return total + 1;
        }
        return total;
      }, 0);
      setUpcomingTasksCount(count);
    }
  }, [tasks]);

  const handleViewModeChange = (event) => {
    const mode = event.target.checked ? 'college' : 'tag';
    setViewMode(mode);
  };

  return (
    <Box className="dashboard-tasks-container dashboard-columns custom-paper">
      <div className="header">
        <CheckBoxIcon sx={{ fontSize: '28px', ml: 1 }} />
        <Typography sx={{ ml: 1, fontSize: '28px' }}>Tasks</Typography>
      </div>

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-evenly" sx={{ m: 1 }}>
            <Box display="flex" alignItems="center">
              <Box color="#C1C9F9" justifyContent="center">
                <CheckBoxIcon sx={{ fontSize: '40px' }} />
              </Box>
              <Typography variant="h5">
                <strong>{completedTasksCount}</strong> Completed
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box color="#43A047">
                <AccessTimeIcon sx={{ fontSize: '40px' }} />
              </Box>
              <Typography variant="h5">
                <strong>{upcomingTasksCount}</strong> Upcoming
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box color="#D32F2F">
                <ErrorIcon sx={{ fontSize: '40px' }} />
              </Box>
              <Typography variant="h5">
                <strong>{overdueTasksCount}</strong> Overdue
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider
            variant="middle"
            sx={{ backgroundColor: '#455FE8', height: '3px' }}
          />
        </Grid>
        <Grid item xs={3} sx={{ m: 2 }}>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ fontSize: '14px' }}>Tag View</Typography>
            <Switch onChange={handleViewModeChange} />
            <Typography sx={{ fontSize: '14px' }}>School View</Typography>
          </Stack>
        </Grid>
        <Grid sx={{ m: 2 }} xs={12}>
          <Stack direction="column">
            {viewMode === 'tag' ? (
              <TagProgressBars tasks={tasks} />
            ) : (
              <CollegeProgressBars tasks={tasks} />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

const DashboardColleges = () => {
  const [userColleges, setUserColleges] = useState([]);
  const [cookies] = useCookies(['tokens']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserColleges = async () => {
      setLoading(true);
      try {
        const response = await getUserColleges(cookies);
        if (response.error) {
          throw response.message;
        } else {
          const { data } = response.body;
          setUserColleges(data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserColleges();
  }, [cookies]);

  const interestedCollegesCount = userColleges.filter(
    (college) => college.attributes.status === 'interested',
  ).length;
  const applyingCollegesCount = userColleges.filter(
    (college) => college.attributes.status === 'applying',
  ).length;
  const appliedCollegesCount = userColleges.filter(
    (college) => college.attributes.status === 'applied',
  ).length;

  return (
    <>
      <Box className="dashboard-colleges-container column-item-1 custom-paper">
        <div className="colleges-header">
          <CheckBoxIcon sx={{ fontSize: '28px', ml: 1 }} />
          <Typography sx={{ ml: 1, fontSize: '28px' }}>Colleges</Typography>
        </div>

        <div className="colleges-status">
          <div className="colleges-item">
            <FavoriteOutlinedIcon sx={{ fontSize: 40, color: '#C1C9F9' }} />

            <Typography className="colleges-text">
              {interestedCollegesCount} Interested
            </Typography>
          </div>

          <div className="colleges-item">
            {' '}
            <DriveFolderUploadOutlinedIcon
              sx={{ fontSize: 40, color: '#C1C9F9' }}
            />
            <Typography className="colleges-text">
              {applyingCollegesCount} Applying
            </Typography>
          </div>

          <div className="colleges-item">
            <CheckCircleOutlineOutlinedIcon
              sx={{ fontSize: 40, color: '#C1C9F9' }}
            />

            <Typography className="colleges-text">
              {appliedCollegesCount} Applied
            </Typography>
          </div>
        </div>
      </Box>
    </>
  );
};

const DashboardTests = () => {
  const [selectedTest, setSelectedTest] = useState('sat');
  const [dashboardTests, setDashboardTests] = useState([]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const date = 365;
  const [cookie] = useCookies(['token']);
  const user = useAuthUser();
  const studentId = user.id;

  useEffect(() => {
    const dateString = formatDateISO(subtractDays(date));
    const getDashboardExams = async () => {
      const response = await getDateTakenResults(
        cookie,
        studentId,
        dateString,
        selectedTest,
        3,
      );

      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else if (selectedTest === 'sat') {
        const datesFromResponse = response.data.map((item) => {
          return {
            id: item.id,
            dateTaken: item.attributes.dateTaken,
            testType: item.attributes.testType,
            totalScore: item.attributes.totalScore,
          };
        });

        setDashboardTests(datesFromResponse);
      } else if (selectedTest === 'act') {
        const datesFromResponse = response.data.map((item) => {
          return {
            id: item.id,
            dateTaken: item.attributes.dateTaken,
            testType: item.attributes.testType,
            compositeScore: item.attributes.compositeScore,
          };
        });
        setDashboardTests(datesFromResponse);
      }
    };

    getDashboardExams();
  }, [date, cookie, studentId, enqueueSnackbar, selectedTest]);

  const handleChange = (event) => {
    setSelectedTest(event.target.checked ? 'act' : 'sat');
  };

  return (
    <Box className="dashboard-tests-container column-item-2 custom-paper">
      <div className="header">
        <PercentIcon sx={{ fontSize: '28px', ml: 1 }} />
        <Typography sx={{ ml: 1, fontSize: '28px' }}>SAT/ACT Tests</Typography>
      </div>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ m: 1 }}
        xs={12}
      >
        <Grid item xs={3} sx={{ m: 2 }}>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ fontSize: '14px' }}>SAT</Typography>
            <Switch checked={selectedTest === 'act'} onChange={handleChange} />
            <Typography sx={{ fontSize: '14px' }}>ACT</Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <IconButton>
            <ControlPointOutlinedIcon
              onClick={() => {
                history.push(`/exam-reports`);
              }}
              sx={{
                color: '#445CEC',
                height: '35px',
                width: '35px',
                cursor: 'pointer',
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      {selectedTest === 'sat' ? (
        <DashboardGraphs
          selectedTest={selectedTest}
          graphData={dashboardTests.map((item) => {
            return {
              date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
              Score: item.totalScore,
            };
          })}
        />
      ) : (
        <DashboardGraphs
          selectedTest={selectedTest}
          graphData={dashboardTests.map((item) => {
            return {
              date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
              Score: item.compositeScore,
            };
          })}
        />
      )}
    </Box>
  );
};

export const EdVizeStudentDashboard = () => {
  const taskUrl = `/v2/tasks/all`;
  const [tasks] = useFetchTasks(taskUrl);
  return (
    <>
      <Box>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ m: 1 }}>
          <Typography variant="h6">
            <strong>My Dashboard</strong>
          </Typography>
          <Typography variant="body1">
            <strong>
              Grade 11 | 3.8 GPA | Anytown High School | Next Level Tutors |
              (Insert Edit Button)
            </strong>
          </Typography>
        </Stack>
      </Box>
      <Box className="dashboard-container">
        <DashboardTasks tasks={tasks} />

        <Box className="dashboard-column">
          <DashboardColleges />
          <DashboardTests />
        </Box>
      </Box>
    </>
  );
};
