import React, { useEffect, useState, useCallback } from 'react';
import useAccessibleStudents from 'hooks/useAccessibleStudents';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useAuthUserEntity } from 'store/UserContext';
import {
  assignStudentToCounselor,
  getStudentsByUserId,
  removeStudentFromCounselor,
} from './functions';

import {
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
  TextField,
  Box,
} from '@mui/material';

const AssignStudentLink = ({ student, onAdded }) => {
  const enabled = typeof student?.id === 'string';

  return (
    <Grid>
      <Button disabled={!enabled} variant="contained" onClick={onAdded}>
        Assign
      </Button>
    </Grid>
  );
};

const SearchStudentsInput = ({ options, onAdded }) => {
  const [selectedStudent, setSelectedStudent] = useState(() => {
    const [firstOption] = options;
    return firstOption || {};
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1,
        m: 1,
        bgcolor: 'background.paper',
        borderRadius: 1,
        alignItems: 'center',
      }}
    >
      <FormControl>
        <Autocomplete
          disablePortal
          id="students"
          options={options}
          getOptionLabel={(option) => option.name || ''}
          value={selectedStudent}
          onChange={(_, newInputValue) => {
            setSelectedStudent(newInputValue);
          }}
          sx={{ width: 300, m: 1 }}
          renderInput={(params) => (
            <TextField {...params} label="Search Students" />
          )}
        />
      </FormControl>
      <AssignStudentLink
        student={selectedStudent}
        onAdded={() => onAdded(selectedStudent)}
      />
    </Box>
  );
};

const AssignedStudentList = () => {
  const [users, setUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [cookie] = useCookies(['tokens']);
  const params = useParams();
  const { students } = useAccessibleStudents({ cookie });
  const entity = useAuthUserEntity();
  console.log(93, entity);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getStudentsByUserId(cookie, params.id);
      if (typeof response === 'string') {
        enqueueSnackbar(response, { variant: 'error' });
      } else {
        const validRoles = ['student'];
        const filteredResults = response.data.filter((request) => {
          return validRoles.includes(request.attributes.relation);
        });

        const result = filteredResults.map((record) => {
          const {
            attributes: { firstName, lastName, email },
            id,
          } = record.relationships.student.data;
          return {
            id,
            firstName,
            lastName,
            name: `${firstName} ${lastName}`,
            email,
          };
        });

        setUsers(result);
      }
    };

    if (params?.id) {
      fetchData();
    }
  }, [enqueueSnackbar, cookie, params?.id]);

  const onAdded = useCallback(
    async (added) => {
      try {
        const response = await assignStudentToCounselor(
          cookie,
          added.id,
          params?.id,
          entity.id,
        );

        if (typeof response === 'string') {
          enqueueSnackbar(response, { variant: 'error' });
        } else {
          enqueueSnackbar('Student assigned successfully!', {
            variant: 'success',
          });
          setUsers((prevState) => {
            return [added, ...prevState];
          });
        }
      } catch (e) {
        console.warn(e);
        return;
      }
    },
    [cookie, params?.id, enqueueSnackbar, entity],
  );

  const removeUser = useCallback(
    async (studentId) => {
      try {
        const response = await removeStudentFromCounselor(
          cookie,
          studentId,
          params?.id,
          entity?.id,
        );

        if (typeof response === 'string') {
          enqueueSnackbar(response, { variant: 'error' });
        } else {
          enqueueSnackbar('Student removed successfully!', {
            variant: 'success',
          });
          setUsers((prevState) => {
            const newState = prevState.filter(
              (student) => student.id !== studentId,
            );
            return newState;
          });
        }
      } catch (e) {
        console.warn(e);
        return;
      }
    },
    [cookie, enqueueSnackbar, entity?.id, params?.id],
  );

  return (
    <Container maxWidth>
      <Typography variant="h1" component="h2" mb={2}>
        Students
      </Typography>
      {students?.length > 0 ? (
        <SearchStudentsInput options={students} onAdded={onAdded} />
      ) : (
        <></>
      )}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              <>
                {users.map((record) => {
                  return (
                    <TableRow key={record.id}>
                      <TableCell>{record?.firstName}</TableCell>
                      <TableCell>{record?.lastName}</TableCell>
                      <TableCell>{record.email}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => removeUser(record.id)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell>No current Users.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AssignedStudentList;
