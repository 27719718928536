import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useQuery from 'hooks/useQuery';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import {
  getStudentExamById,
  scoreExamById,
  getDateTakenResults,
  auditExamById,
} from './functions';
import { useHistory } from 'react-router-dom';
import { subtractDays, formatDateISO } from 'utils/dates';
import { useUserRole } from '../../store/UserContext';

//MUI IMPORTS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

//RECHART IMPORTS
import {
  BarChart,
  Bar,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { useLayoutEffect } from 'react';

const Bold = ({ children }) => {
  return <Box sx={{ display: 'inline', fontWeight: 'bold' }}>{children}</Box>;
};

// TABLE DATA FOR REPORTS
export const StudentCumulativeScores = ({ studentId, testType }) => {
  const [testDates, setTestDates] = useState([]);
  const [date, setDate] = useState('');
  const [open, setOpen] = useState(false);
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const userRole = useUserRole();

  useEffect(() => {
    const getCumulativeResults = async () => {
      const dateString = formatDateISO(subtractDays(date));
      const response = await getDateTakenResults(
        cookie,
        studentId,
        dateString,
        testType,
      );

      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else if (testType === 'sat') {
        const datesFromResponse = response.data.map((item) => {
          return {
            id: item.id,
            dateTaken: item.attributes.dateTaken,
            testType: item.attributes.testType,
            verbalScore: item.attributes.verbalScore,
            mathScore: item.attributes.mathScore,
            totalScore: item.attributes.totalScore,
          };
        });
        setTestDates(datesFromResponse);
      } else if (testType === 'act') {
        const datesFromResponse = response.data.map((item) => {
          return {
            id: item.id,
            dateTaken: item.attributes.dateTaken,
            testType: item.attributes.testType,
            mathScore: item.attributes.mathScore,
            englishScore: item.attributes.englishScore,
            readingScore: item.attributes.readingScore,
            scienceScore: item.attributes.scienceScore,
            compositeScore: item.attributes.compositeScore,
          };
        });
        setTestDates(datesFromResponse);
      }
    };

    if (typeof date === 'number') {
      getCumulativeResults();
    }
  }, [date, cookie, studentId, enqueueSnackbar, testType]);

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid item xs={9}>
        <Typography>Select Dates</Typography>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="test-dates">Date</InputLabel>
          <Select
            labelId="test-dates"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            testDates={testDates}
            value={date}
            label="Date"
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Date</em>
            </MenuItem>
            <MenuItem value={7}>Past 7 Days</MenuItem>
            <MenuItem value={14}>Past 14 Days</MenuItem>
            <MenuItem value={30}>Past 30 Days</MenuItem>
            <MenuItem value={60}>Past 60 Days</MenuItem>
            <MenuItem value={90}>Past 90 Days</MenuItem>
            <MenuItem value={365}>12 Months</MenuItem>
          </Select>
        </FormControl>
        <Grid xs={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 700 }}
              size="small"
              aria-label="customized table"
            >
              {testType === 'sat' ? (
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell align="right">Test Type</StyledTableCell>
                    <StyledTableCell align="right">
                      Verbal Score
                    </StyledTableCell>
                    <StyledTableCell align="right">Math Score</StyledTableCell>
                    <StyledTableCell align="right">Total Score</StyledTableCell>
                    <StyledTableCell align="right" />
                  </TableRow>
                </TableHead>
              ) : (
                <></>
              )}
              {testType === 'act' ? (
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell align="right">Test Type</StyledTableCell>
                    <StyledTableCell align="right">Math Score</StyledTableCell>
                    <StyledTableCell align="right">
                      English Score
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Reading Score
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Science Score
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Composite Score
                    </StyledTableCell>
                    <StyledTableCell align="right" />
                  </TableRow>
                </TableHead>
              ) : (
                <></>
              )}
              {testType === 'sat' ? (
                <TableBody>
                  {testDates.length > 0 ? (
                    testDates.map((row) => (
                      <StyledTableRow key={row.date}>
                        <StyledTableCell component="th" scope="row">
                          {new Date(row.dateTaken).toLocaleDateString('en-CA')}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.testType.toUpperCase()}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.verbalScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.mathScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.totalScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {userRole === 'counselor' ? (
                            <button
                              onClick={() => {
                                history.push(
                                  `/counselor/students/${studentId}/exam/${row.id}`,
                                );
                              }}
                            >
                              EDIT
                            </button>
                          ) : (
                            <></>
                          )}
                          {userRole === 'account_admin' ? (
                            <button
                              onClick={() => {
                                history.push(
                                  `/account-admin/students/${studentId}/exam/${row.id}`,
                                );
                              }}
                            >
                              EDIT
                            </button>
                          ) : (
                            <></>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        No test data for date selected
                      </StyledTableCell>
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                    </StyledTableRow>
                  )}
                </TableBody>
              ) : (
                <></>
              )}
              {testType === 'act' ? (
                <TableBody>
                  {testDates.length > 0 ? (
                    testDates.map((row) => (
                      <StyledTableRow key={row.date}>
                        <StyledTableCell component="th" scope="row">
                          {new Date(row.dateTaken).toLocaleDateString('en-CA')}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.testType.toUpperCase()}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.mathScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.englishScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.readingScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.scienceScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.compositeScore}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {userRole === 'counselor' ? (
                            <button
                              onClick={() => {
                                history.push(
                                  `/counselor/students/${studentId}/exam/${row.id}`,
                                );
                              }}
                            >
                              EDIT
                            </button>
                          ) : (
                            <></>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        No test data for date selected
                      </StyledTableCell>
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                    </StyledTableRow>
                  )}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {testType === 'sat' ? (
        <Grid container>
          <Grid item xs={6} p={2}>
            <Typography align="center">
              <Bold>Total Scores</Bold>
            </Typography>
            <TotalTestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.totalScore,
                };
              })}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <Typography align="center">
              <Bold>Verbal Scores</Bold>
            </Typography>
            <TestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.verbalScore,
                };
              })}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <Typography align="center">
              <Bold>Math Scores</Bold>
            </Typography>
            <TestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.mathScore,
                };
              })}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {testType === 'act' ? (
        <Grid container>
          <Grid xs={6} p={2}>
            <Typography align="center">
              <Bold>Composite Scores</Bold>
            </Typography>
            <TotalTestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.compositeScore,
                };
              })}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <Typography align="center">
              <Bold>Math Scores</Bold>
            </Typography>
            <TestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.mathScore,
                };
              })}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <Typography align="center">
              <Bold>English Scores</Bold>
            </Typography>
            <TestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.englishScore,
                };
              })}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <Typography align="center">
              <Bold>Reading Scores</Bold>
            </Typography>
            <TestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.readingScore,
                };
              })}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <Typography align="center">
              <Bold>Science Scores</Bold>
            </Typography>
            <TestGraphs
              testType={testType}
              graphData={testDates.map((item) => {
                return {
                  date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
                  Score: item.scienceScore,
                };
              })}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

// GRAPH COMPONENT FOR REPORTS
export const TotalTestGraphs = ({
  graphData,
  testType,
  variant = 'version1',
}) => {
  if (variant === 'version2') {
    return (
      <BarChart width={700} height={210} data={graphData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        {testType === 'sat' ? (
          <YAxis type="number" domain={[0, 1600]} />
        ) : (
          <YAxis type="number" domain={[0, 36]} />
        )}
        <Tooltip />
        <Legend />
        <Bar dataKey="Score" barSize={50} fill="#455FE8" />
      </BarChart>
    );
  }
  return (
    <BarChart
      width={700}
      height={300}
      data={graphData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      {testType === 'sat' ? (
        <YAxis type="number" domain={[0, 1600]} />
      ) : (
        <YAxis type="number" domain={[0, 36]} />
      )}
      <Tooltip />
      <Legend />
      <Bar dataKey="Score" barSize={50} fill="#455FE8" />
    </BarChart>
  );
};

const TestGraphs = ({ graphData, testType }) => {
  return (
    <BarChart
      width={700}
      height={300}
      data={graphData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      {testType === 'sat' ? (
        <YAxis type="number" domain={[0, 800]} />
      ) : (
        <YAxis type="number" domain={[0, 36]} />
      )}
      <Label position="top" value="Total Score" />
      <Tooltip />
      <Legend />
      <Bar dataKey="Score" barSize={50} fill="#455FE8" />
    </BarChart>
  );
};

const SectionGraphs = ({ performanceData }) => {
  return (
    <BarChart
      width={700}
      height={500}
      data={performanceData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        height={130}
        angle={-40}
        textAnchor="end"
        scaleToFit="true"
        verticalAnchor="start"
        interval={0}
      />
      <Label />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Score" fill="#455FE8" />
    </BarChart>
  );
};

// STUDENT SELECT MENU
const StudentTestSelect = ({ date, setDate, testType, studentId }) => {
  const [open, setOpen] = useState(false);
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();
  const [studentTests, setStudentTests] = useState([]);

  useLayoutEffect(() => {
    setDate('');
  }, [setDate]);

  useEffect(() => {
    const getStudentTestSelect = async () => {
      const response = await getStudentExamById(cookie, studentId, testType);
      if (response.error) {
        enqueueSnackbar(response.error, { variant: 'error' });
      } else {
        setStudentTests(
          response.data.map((item) => {
            return { id: item.id, dateTaken: item.attributes.dateTaken };
          }),
        );
      }
    };

    if (typeof studentId === 'string') {
      getStudentTestSelect();
    }
  }, [studentId, cookie, enqueueSnackbar, testType]);

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Typography>Select Test</Typography>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-controlled-open-select-label">
          Select Test
        </InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          defaultValue=""
          value={date}
          label="Student Test"
          onChange={handleChange}
        >
          {studentTests.map((test) => {
            return (
              <MenuItem key={test.id} value={test.id}>
                {new Date(test.dateTaken).toDateString()}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#414141',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableData = ({ data }) => {
  return (
    <Grid xs={6}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Skill Name</StyledTableCell>
              <StyledTableCell align="right">Correct</StyledTableCell>
              <StyledTableCell align="right">Total</StyledTableCell>
              <StyledTableCell align="right">Percent</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <StyledTableRow key={item.name}>
                  <StyledTableCell component="th" scope="row">
                    {item.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {item.correct}
                  </StyledTableCell>
                  <StyledTableCell align="right">{item.total}</StyledTableCell>
                  <StyledTableCell align="right">
                    {item.total > 0 ? item.percentage : 'N/A'}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const AccordionCategory = ({ category, graphData }) => {
  return (
    <Accordion sx={{ m: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aira-controls="sat-exam-section-1"
        id="sat-exam-section-1"
        sx={{ backgroundColor: '#455FE8' }}
      >
        <Typography sx={{ color: '#fafafa', width: '25%', flexShrink: 0 }}>
          <Bold>{category.name}</Bold>
        </Typography>
        <Typography sx={{ color: '#fafafa', width: '25%', ml: 1 }}>
          <Bold>
            {category.correct} / {category.total} Questions
          </Bold>
        </Typography>
        <Typography sx={{ color: '#fafafa', ml: 1 }}>
          <Bold>{category.percentage}% Correct</Bold>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ m: 1 }}>
          <TableData data={category.subcategories} />
          <Typography align="center">
            <Bold>Performance by Subject (%)</Bold>
            <SectionGraphs performanceData={graphData} />
          </Typography>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TabManager = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

// SAT TABS AND SECTIONS FOR REPORT
const SatTabsManager = ({ date, studentId }) => {
  const [testData, setTestData] = useState();
  const [value, setValue] = useState(0);
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      const response = await scoreExamById(cookie, studentId, date);

      if (response.error) {
        enqueueSnackbar(response.message, { varient: 'error' });
      } else {
        const { reading, math, writing } = response.data.relationships;
        setTestData({
          readingSkills: reading,
          writingSkills: writing,
          mathSkills: math,
        });
      }
    };
    fetchData();
  }, [date, cookie, enqueueSnackbar, studentId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', overFlow: 'true' }}>
      <Grid sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="sat-academic-sections"
        >
          <Tab label="Math" {...TabManager(0)}></Tab>
          <Tab label="Writing and Language" {...TabManager(1)}></Tab>
          <Tab label="Reading" {...TabManager(2)}></Tab>
          <Tab label="Answers" {...TabManager(3)}></Tab>
        </Tabs>
      </Grid>

      <TabPanel key={0} value={value} index={0}>
        {testData?.mathSkills.categories.map((category) => {
          return (
            <AccordionCategory
              key={category.name}
              category={category}
              graphData={testData?.mathSkills.categories.reduce((acc, sub) => {
                const { name, percentage } = sub;
                return acc.concat({ name, Score: percentage });
              }, [])}
            />
          );
        })}
      </TabPanel>
      <TabPanel key={1} value={value} index={1}>
        {testData?.writingSkills.categories.map((category) => {
          // only returns Writing and Language as a category for the skills and graph
          if (category.name === 'Writing and Language') {
            return (
              <AccordionCategory
                key={category.name}
                category={category}
                graphData={testData?.writingSkills.categories.reduce(
                  (acc, sub) => {
                    // this keeps this specific category out of the graph data
                    if (sub.name === 'Writing and Language') {
                      return acc;
                    }
                    const { name, percentage } = sub;
                    return acc.concat({ name, Score: percentage });
                  },
                  [],
                )}
              />
            );
          }

          // default return nothing
          return <></>;
        })}
      </TabPanel>
      <TabPanel key={2} value={value} index={2}>
        {testData?.readingSkills.categories.map((category) => {
          const [questionTypeData] = testData?.readingSkills.categories;
          return (
            <AccordionCategory
              key={category.name}
              category={category}
              graphData={questionTypeData.subcategories?.reduce((acc, sub) => {
                const { name, percentage } = sub;
                if (name === 'TOTAL') return acc;
                return acc.concat({ name, Score: percentage });
              }, [])}
            />
          );
        })}
      </TabPanel>
      <TabPanel key={3} value={value} index={3}>
        <DisplayExamAnswers examId={date} studentId={studentId} />
      </TabPanel>
    </Box>
  );
};

// ACT TABS AND SECTIONS FOR REPORT
const ActTabsManager = ({ date, studentId }) => {
  const [testData, setTestData] = useState();
  const [value, setValue] = useState(0);
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      const response = await scoreExamById(cookie, studentId, date);

      if (response?.error) {
        enqueueSnackbar(response.error, { variant: 'error' });
      } else {
        const { reading, english, math, science } = response.data.relationships;
        setTestData({
          readingSkills: reading,
          englishSkills: english,
          mathSkills: math,
          scienceSkills: science,
        });
      }
    };
    fetchData();
  }, [date, cookie, enqueueSnackbar, studentId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', overFlow: 'true' }}>
      <Grid sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="act-academic-sections"
        >
          <Tab label="English" {...TabManager(0)}></Tab>
          <Tab label="Math" {...TabManager(1)}></Tab>
          <Tab label="Reading" {...TabManager(2)}></Tab>
          <Tab label="Science" {...TabManager(3)}></Tab>
          <Tab label="Answers" {...TabManager(4)}></Tab>
        </Tabs>
      </Grid>

      <TabPanel key={0} value={value} index={0}>
        {testData?.englishSkills.categories.map((category) => {
          // only returns Writing and Language as a category for the skills and graph
          if (category.name === 'Writing and Language') {
            return (
              <AccordionCategory
                key={category.name}
                category={category}
                graphData={testData?.englishSkills.categories.reduce(
                  (acc, sub) => {
                    // this keeps this specific category out of the graph data
                    if (sub.name === 'Writing and Language') {
                      return acc;
                    }
                    const { name, percentage } = sub;
                    return acc.concat({ name, Score: percentage });
                  },
                  [],
                )}
              />
            );
          }

          // default return nothing
          return <></>;
        })}
      </TabPanel>
      <TabPanel key={1} value={value} index={1}>
        {testData?.mathSkills.categories.map((category) => {
          return (
            <AccordionCategory
              key={category.name}
              category={category}
              graphData={testData?.mathSkills.categories.reduce((acc, sub) => {
                const { name, percentage } = sub;
                return acc.concat({ name, Score: percentage });
              }, [])}
            />
          );
        })}
      </TabPanel>
      <TabPanel key={2} value={value} index={2}>
        {testData?.readingSkills.categories.map((category) => {
          const [questionTypeData] = testData?.readingSkills.categories;
          return (
            <AccordionCategory
              key={category.name}
              category={category}
              graphData={questionTypeData.subcategories?.reduce((acc, sub) => {
                const { name, percentage } = sub;
                if (name === 'TOTAL') return acc;
                return acc.concat({ name, Score: percentage });
              }, [])}
            />
          );
        })}
      </TabPanel>
      <TabPanel key={3} value={value} index={3}>
        {testData?.scienceSkills.categories.map((category) => {
          const [questionTypeData] = testData?.scienceSkills.categories;
          return (
            <AccordionCategory
              key={category.name}
              category={category}
              graphData={questionTypeData.subcategories?.reduce((acc, sub) => {
                const { name, percentage } = sub;
                if (name === 'TOTAL') return acc;
                return acc.concat({ name, Score: percentage });
              }, [])}
            />
          );
        })}
      </TabPanel>
      <TabPanel key={4} value={value} index={4}>
        <DisplayExamAnswers examId={date} studentId={studentId} />
      </TabPanel>
    </Box>
  );
};

const QuestionAnswerTable = ({ rows, title }) => {
  return (
    <>
      <h4>{title?.toUpperCase().replace('_', ' ')}</h4>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="test answers">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell align="left">Correct Answer(s)</TableCell>
              <TableCell align="left">Student Answer</TableCell>
              <TableCell align="left">Correct</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.questionKey}
                </TableCell>
                <TableCell align="left">{row.correctAnswer}</TableCell>
                <TableCell align="left">{row?.studentAnswer}</TableCell>
                <TableCell
                  align="left"
                  sx={{ color: row.correct ? 'blue' : 'red' }}
                >
                  {row.correct ? 'Correct' : 'Incorrect'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const DisplayExamAnswers = ({ examId, studentId }) => {
  const [data, setData] = useState();
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const fetchData = async () => {
      const response = await auditExamById(cookie, studentId, examId);

      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        setData(response.data);
      }
    };
    fetchData();
  }, [cookie, studentId, examId, enqueueSnackbar]);

  return (
    <>
      <p>
        Type: {data?.attributes?.type.toUpperCase()}, Exam Code:{' '}
        {data?.attributes?.examCode}
      </p>
      {data ? (
        Object.entries(data?.meta).map(([key, value]) => {
          return <QuestionAnswerTable rows={value} title={key} />;
        })
      ) : (
        <></>
      )}
    </>
  );
};

// RENDER COMPONENTS
export const StudentExamReport = ({ defaultState, sId }) => {
  const [studentId, setStudentId] = useState(() => {
    if (sId) return sId;
    return;
  });
  const [testType, setTestType] = useState(() => {
    if (defaultState) {
      const { type } = defaultState;
      return type;
    }
    return 'sat';
  });
  const [date, setDate] = useState('');
  const query = useQuery();
  const history = useHistory();
  const userFromState = history?.location?.state;

  useEffect(() => {
    setStudentId((prevState) => {
      if (!prevState) {
        const studentIdQuery = query.get('studentId');
        if (studentIdQuery) {
          return studentIdQuery;
        }
      }
      return prevState;
    });
  }, [query]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Reports</Typography>
        </Grid>
        <Grid container spacing={2} xs={12}>
          <Grid item xs={2} sx={{ m: 1 }}>
            <RadioGroup
              value={testType}
              onChange={(evt) => setTestType(evt.target.value)}
              row
              justifyContent="center"
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="sat" control={<Radio />} label="SAT" />
              <FormControlLabel value="act" control={<Radio />} label="ACT" />
            </RadioGroup>
          </Grid>
        </Grid>
        {testType === 'sat' ? (
          <Box>
            <Grid container spacing={2} xs={12}>
              <StudentCumulativeScores
                studentId={studentId}
                testType={testType}
              />
              <Grid item xs={12}>
                {studentId ? (
                  <StudentTestSelect
                    studentId={studentId}
                    date={date}
                    setDate={setDate}
                    testType={testType}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                {date ? (
                  <SatTabsManager date={date} studentId={studentId} />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        {testType === 'act' ? (
          <Box>
            <Grid container spacing={2} xs={12}>
              <StudentCumulativeScores
                studentId={studentId}
                testType={testType}
              />
              <Grid item xs={12}>
                {studentId ? (
                  <StudentTestSelect
                    studentId={studentId}
                    date={date}
                    setDate={setDate}
                    testType={testType}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                {date ? (
                  <ActTabsManager date={date} studentId={studentId} />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};
