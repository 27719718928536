/**
 * @param {Array} arr
 * @returns {Array}
 * @description
 * * Filter by status: completed, in-progress, not-started
 * * Filter by sorter: a-z, z-a, date-asc, date-desc
 * * Apply the filters that you want to use on the array to the filters array
 * * Apply the sorters you want to use on the array to the sorters array
 * * Run the array through the middlewareFilterAndSort function
 *
 */

const filtersMap = {
  completed: (arr) => arr.filter((item) => item.completed),
  upcoming: (arr) => arr.filter((item) => !item.completed),
  general: (arr) => arr.filter((item) => !item.college),
  school: (arr) => arr.filter((item) => item.college),
  profile: (arr) => arr.filter((item) => item.name),
  essays: (arr) => arr.filter((item) => item.name),
  recommendations: (arr) => arr.filter((item) => item.name),
  collegeList: (arr) => arr.filter((item) => item.name),
  finances: (arr) => arr.filter((item) => item.name),
  sat: (arr) => arr.filter((item) => item.name),
  act: (arr) => arr.filter((item) => item.name),
  other: (arr) => arr.filter((item) => item.name),
  allStatus: (arr) => arr,
  allType: (arr) => arr,
  allTags: (arr) => arr,
};

const sortersMap = {
  aToZ: (arr) => arr.sort((a, b) => a.name.localeCompare(b.name)),
  zToA: (arr) => arr.sort((a, b) => b.name.localeCompare(a.name)),
  dateAsc: (arr) => arr.sort((a, b) => a.dueDate - b.dueDate),
  dateDesc: (arr) => arr.sort((a, b) => b.dueDate - a.dueDate),
};

export const middlewareFilterAndSort = (
  arr = [],
  filters,
  sorter,
  search,
  tags,
) => {
  let newArr = [...arr];

  if (search) {
    newArr = newArr.filter((item) => {
      return item.college?.data?.attributes?.name
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }

  if (tags !== 'allTags') {
    newArr = newArr.filter((item) => {
      if (Array.isArray(item.tags) && item.tags.length > 0) {
        return item.tags.some(
          (tag) => tag.name.toLowerCase() === tags.toLowerCase(),
        );
      }
      return false;
    });
  }

  filters.forEach((filter) => {
    newArr = filtersMap[filter](newArr);
  });

  return sortersMap[sorter](newArr);
};
