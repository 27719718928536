import { emailValidation } from 'utils/joiValidation';
import React, { useState } from 'react';
import edVize from '../assets/edVIZE_transparent.png';
import { useSnackbar } from 'notistack';
import passwordReset from '../components/userManagmentFunctions/passwordReset';

// MUI IMPORTS
import { Button, TextField, Box, Grid, Typography } from '@mui/material';

export default function SignIn({ setStatus }) {
  const [emailError, setEmailError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const validate = (data) => {
    const { message: error, type } = emailValidation(data);
    if (type === 'valid') {
      return true;
    } else {
      setEmailError(error);
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailError('');
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    if (validate(email)) {
      handleResetRequest(email);
    }
  };

  const handleResetRequest = async (email) => {
    try {
      const response = await passwordReset(email);
      if (response.status === 200) {
        const jsonResponse = await response.json();
        enqueueSnackbar(jsonResponse?.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.statusText, { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Grid item xs={12} sx={{ maxHeight: '100vh' }}>
      <Box
        sx={{
          mt: 10,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{ maxHeight: '150px', marginTop: 50 }}
          src={edVize}
          alt={'Vize Logo'}
        />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 10 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            error={!!emailError}
            helperText={emailError}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Request Reset
          </Button>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                align="center"
                component="p"
                variant="body1"
                color="primary.main"
                onClick={() => setStatus('si')}
                sx={{ mt: 2, cursor: 'pointer' }}
              >
                Have an account?
                <br /> Sign In
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography
                align="center"
                component="p"
                variant="body1"
                color="primary.main"
                onClick={() => setStatus('su')}
                sx={{ mt: 2, cursor: 'pointer' }}
              >
                Don't have an account? <br /> Sign Up
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
