import { middlewareFilterAndSort } from './functions/filtering_and_sorting';
import React, { useState, useEffect } from 'react';
import { useFetchTasks, useFetchColleges } from './functions/hooks';
import AllTasks from './AllTasks';
import TaskCalendar from '../task-calendar/Layout';
import AnalyticsService from '../../../analyticsService';

// MUI IMPORTS
import {
  Box,
  Grid,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Divider,
  Tabs,
  Tab,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledTabs = styled(Tabs)({
  '& .MuiTab-root': {
    color: '#8F8D93',
    backgroundColor: '#D9D9D9',
    borderRadius: '15px 15px 0 0',
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#FFFFFF',
    backgroundColor: '#445CEC',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#445CEC',
  },
});

const SelectGroup = ({ handleChange, value, label, options }) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
      <InputLabel
        id={`${label}-select`}
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          color: 'rgba(69, 95, 232, 1)',
          backgroundColor: 'textPrimary',
          borderRadius: '4px',
          textDecoration: 'none',
        }}
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          color: 'rgba(69, 95, 232, 1)',
          backgroundColor: 'textPrimary',
          borderRadius: '4px',
          textDecoration: 'none',
        }}
        labelId={`${label}-label`}
        id={`${label}-select`}
        value={value}
        onChange={handleChange}
      >
        {options &&
          options.map((item, index) => {
            return (
              <MenuItem key={item.value + index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

const FilterAndSort = ({
  type,
  setType,
  date,
  setDate,
  setSorter,
  status,
  setStatus,
  tags,
  setTags,
  view,
}) => {
  const selects = [
    {
      label: 'Task Type',
      value: type,
      options: [
        { label: 'General', value: 'general' },
        { label: 'School Specific', value: 'school' },
        { label: 'All', value: 'allType' },
      ],
      handleChange: (event) => {
        setType(event.target.value);
      },
    },
    {
      label: 'Date',
      value: date,
      options: [
        { label: 'Ascending', value: 'dateAsc' },
        { label: 'Descending', value: 'dateDesc' },
      ],
      handleChange: (event) => {
        setDate(event.target.value);
        setSorter(event.target.value);
      },
    },
    {
      label: 'Task Status',
      value: status,
      options: [
        { label: 'Upcoming', value: 'upcoming' },
        { label: 'Completed', value: 'completed' },
        { label: 'All', value: 'allStatus' },
      ],
      handleChange: (event) => {
        setStatus(event.target.value);
      },
    },
    {
      label: 'Task Tag',
      value: tags,
      options: [
        { label: 'Profile', value: 'profile' },
        { label: 'Essays', value: 'essays' },
        { label: 'Recommendations', value: 'recommendations' },
        { label: 'College List', value: 'collegeList' },
        { label: 'Finances', value: 'finances' },
        { label: 'SAT', value: 'sat' },
        { label: 'ACT', value: 'act' },
        { label: 'Other', value: 'other' },
        { label: 'All', value: 'allTags' },
      ],
      handleChange: (event) => {
        setTags(event.target.value);
      },
    },
  ];

  return (
    <Grid container item direction="row">
      <Grid
        container
        item
        xs={12}
        sx={{
          m: 5,
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        {view === 'list'
          ? selects.map((select) => (
              <SelectGroup key={select.label} {...select} />
            ))
          : selects
              .filter((select) => select.label !== 'Date')
              .map((select) => <SelectGroup key={select.label} {...select} />)}
      </Grid>
    </Grid>
  );
};

const Layout = () => {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Tasks' });
  }, []);

  const taskUrl = `/v2/tasks/all`;
  const [tasks, setTasks, taskLoading, taskError] = useFetchTasks(taskUrl);
  const [filters, setFilters] = useState([]);
  const [view, setView] = useState('list');

  const [type, setType] = useState('allType');
  const [status, setStatus] = useState('allStatus');
  const [date, setDate] = useState('dateAsc');
  const [tags, setTags] = useState('allTags');

  const [sorter, setSorter] = useState(date);
  const [search, setSearch] = useState(null);

  const [SCPage] = useState(1);
  const collegesUrl = `/users/saved-colleges?currentPage=${SCPage}&pageSize=10`;
  const [colleges] = useFetchColleges(collegesUrl);

  useEffect(() => {
    setFilters([type, status, tags]);
  }, [type, status, tags]);

  const [mutatedTasks, setMutatedTasks] = useState([]);

  useEffect(() => {
    if (tasks) {
      setMutatedTasks(
        middlewareFilterAndSort(tasks, filters, sorter, search, tags),
      );
    }
  }, [tasks, filters, sorter, search, tags]);

  const changeView = (event) => {
    setView(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setView(newValue);
  };

  let content;
  if (taskLoading) {
    content = <p>Loading tasks...</p>;
  } else if (taskError) {
    content = <p>Failed to load tasks: {taskError.message}</p>;
  } else {
    content =
      view === 'list' ? (
        <AllTasks
          tasks={mutatedTasks}
          setTasks={setTasks}
          taskLoading={taskLoading}
          taskError={taskError}
        />
      ) : (
        <TaskCalendar
          tasks={mutatedTasks}
          setTasks={setTasks}
          taskLoading={taskLoading}
          taskError={taskError}
        />
      );
  }

  return (
    <Box sx={{ spacing: 1 }}>
      <Grid container xs={12}>
        <Grid item>
          <Typography variant="h1" component="h2" sx={{ m: 1 }}>
            Tasks
          </Typography>
          <Typography variant="body1" component="p" sx={{ m: 1 }}>
            View and manage all of your tasks below
          </Typography>
        </Grid>
      </Grid>
      <StyledTabs value={view} onChange={handleTabChange} variant="fullWidth">
        <Tab label="List View" value="list" sx={{ mr: 1 }} />
        <Tab label="Calendar View" value="calendar" />
      </StyledTabs>
      <Paper elevation={3}>
        <Box>
          <FilterAndSort
            type={type}
            setType={setType}
            date={date}
            setDate={setDate}
            tags={tags}
            setTags={setTags}
            setSorter={setSorter}
            status={status}
            setStatus={setStatus}
            colleges={colleges}
            setSearch={setSearch}
            view={view}
            changeView={changeView}
          />
          <Divider
            variant="middle"
            sx={{ height: '3px', backgroundColor: '#455FE8', m: 3 }}
          />
          <Box
            sx={{
              height:
                'calc(100vh - 64px - 64px - 24px - 24px - 104.02px - 50px - 103px - 68px)',
              overflowY: 'auto',
              m: 2,
            }}
          >
            {content}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Layout;
